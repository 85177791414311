.layout-sidenav, .layout-navbar {
  min-height: 66px!important;
}

.container-fluid{
  margin-top: 66px!important;
}

a{
  cursor: pointer;
}

.table td{
  vertical-align: middle;
}

.pagination{
  justify-content: flex-end;
  margin-bottom: 0;
}

.pagination .page-item{
  cursor: pointer;
}